import { AppEnvironment, FirebaseEnvironment, StoreURL } from './entities/app_envirinment'

const firebase: FirebaseEnvironment = {
    apiKey: 'AIzaSyBCO7NN4Ty6egpf9quRxS7b1siP_DtWmYg',
    authDomain: 'mc-app-cabbaton-dev.firebaseapp.com',
    projectId: 'mc-app-cabbaton-dev',
    storageBucket: 'mc-app-cabbaton-dev.appspot.com',
    messagingSenderId: '979386911454',
    appId: '1:979386911454:web:e9f4b01e8e03365d80104f',
    measurementId: 'G-FRRSHQ3ZDE',
}

const storeURL: StoreURL = {
    ios: 'https://apps.apple.com/app/id6473886199',
    android: 'https://play.google.com/store/apps/details?id=jp.cabbaton.members',
}

export const environment: AppEnvironment = {
    production: false,
    brandCode: 'cabbaton',
    env: 'dev',
    firebase,
    hostingBaseURL: 'https://app-dev.cabbaton.jp',
    appScheme: 'cabbaton-monogatari-app-dev',
    storeURL,
}
